<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div>
    <v-card class="elevation-x py-3">
      <v-card-title class="pt-0 font-alt">Dossiers annulés</v-card-title>
      <v-card-subtitle class="font-weight-light text-caption font-alt">Liste de vos dossiers annulés</v-card-subtitle>
      <admissions-list cancelled />
    </v-card>
  </div>
</template>

<script>
  import AdmissionsList from '@/modules/professionals/components/list/AdmissionsList'
  export default {
    name: 'AdmissionsCancelled',
    components: {AdmissionsList}
  }
</script>
